.App {
  max-width: 100% /*50rem*/;
}

.main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 15%;
}

.box {
  width: 90%;
  /* max-width: 300px; */
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  margin-bottom: 1rem;
  color: #272727;
  background: #f8f9fa;
  border: 1px solid rgb(170, 170, 170);
  border-radius: 0.2rem;
  padding: 1rem;
  /* box-shadow: 3px 3px 5px rgba(34, 41, 48, 0.3); */
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #222930;
}

.popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.5);
}
.popup_inner {
  font-size: smaller;
  position: absolute;
  max-width: 50rem;
  left: 10%;
  right: 10%;
  top: 20%;
  bottom: 10%;
  margin: auto;
  color: #272727;
  background: #f8f9fa;
  border: 1px solid rgb(170, 170, 170);
  border-radius: 0.2rem;
  padding: 1rem;
  overflow-x: hidden;
  overflow-y: auto;
  text-align: left;
}

.Footer {
  position: fixed;
  padding-top: 15px;
  padding-bottom: 3px;
  height: 50;
  bottom: 0px;
  width: 100%;
  margin: 0%;
  color: #272727;
  background: #f8f9fa;
  text-align: center;
  font-size: smaller;
  border-top: 1px solid rgb(170, 170, 170);
}
