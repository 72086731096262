@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");
body {
  font-family: "Montserrat", sans-serif;
}

* {
  font-family: "Montserrat", sans-serif;
}

.forceMobile {
  max-width: 45em;
}

.companyPic {
  max-height: auto;
  max-width: 15em;
  padding-top: 2%;
  padding-bottom: 2%;
}
